<template>
  <section class="m-partner m-scroll">
    <!-- 头部导航 -->
    <m-header :title="title"></m-header>

    <template v-if="tab == 1 && status == 0">
      <div class="form">
            <p class="label">Company Name</p>
            <input v-model="form.name"  type="text" class="reset-input" placeholder="Please enter shop name">

            <p class="label">Contact Email<span v-if="emailTrue">Enter the correct email address</span></p>
            <input v-model="form.email" type="text" class="reset-input" placeholder="E.g：stehr.myah@santa.us">

            <p class="label">Contact person</p>
            <div class="input-box">
              <input v-model="form.firstname" type="text" class="reset-input" placeholder="First name">
              <input v-model="form.lastname"  type="text" class="reset-input" placeholder="Last name">
            </div>

            <p class="label">Contact number<span v-if="phoneTrue">Enter the correct contact number</span></p>
            <div class="input-phone">
              <div @click="telPicker = true">
                <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.phoneType == '1'">
                <img src="@/assets/public/china.png" alt="canada" v-else>
                <span>+{{form.phoneType}}</span>
              </div>
              <input v-model="form.phone" type="text" >
            </div>

            <div class="footer">
              <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Done</van-button>
              <!-- <van-checkbox v-model="agree" icon-size="12px" checked-color="#F03C18"  style="margin-top:10px">
                <p class="times">Read and agree to <span>《 Becoming a Partner Rules and Conditions 》</span></p>
              </van-checkbox>  -->
            </div>
          </div>
    </template>
    <template v-else-if="tab == 2 && status == 0">
      <div class="form">
        <p class="label">Contact Email</p>
        <input v-model="form.email" type="text" class="reset-input" placeholder="E.g：stehr.myah@santa.us">

        <p class="label">Contact person</p>
        <div class="input-box">
          <input v-model="form.firstname" type="text" class="reset-input" placeholder="First name">
          <input v-model="form.lastname"  type="text" class="reset-input" placeholder="Last name">
        </div>

        <p class="label">Contact number</p>
        <div class="input-phone">
          <div @click="telPicker = true">
            <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.phoneType == '1'">
            <img src="@/assets/public/china.png" alt="canada" v-else>
            <span>+{{form.phoneType}}</span>
          </div>
          <input v-model="form.phone" type="text" >
        </div>

        <div class="footer">
          <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Done</van-button>
          <!-- <van-checkbox v-model="agree" icon-size="12px" checked-color="#F03C18"  style="margin-top:10px">
            <p class="times">Read and agree to <span>《 Becoming a Partner Rules and Conditions 》</span></p>
          </van-checkbox>  -->
        </div>
      </div>
    </template>
    
    <template> 
      <!-- 申请状态 -->
      <div class="application-progress" v-if="status == 1">
        <img src="@/assets/payment/success@2x.png" alt="">
        <h3>Pending review</h3>
        <p>You have submitted your application successfully. We will review the materials you submitted as soon as possible. Please be patient and wait for the review result</p>
        <div class="back" style="margin-top:50px" @click="$router.back()">Back</div>
      </div>
      <div class="application-progress" v-else-if="status == 2">
        <img src="@/assets/payment/success@2x.png" alt="">
        <h3>Approved</h3>
        <p>Your application has been approved</p>
        <div class="back" style="margin-top:50px" v-if="apply_type != 1" @click="status = 0;">Promotion of corporate partners</div>
        <div class="back" v-else @click="$router.back()">Back</div>
      </div>
      <div class="application-fail" v-else-if="status == 3">
        <img src="@/assets/payment/fail.png" alt="">
        <h3>Sorry, the review failed</h3>
        <p>Your application has not been approved, we have contacted you by phone, you can also reapply, please be sure to fill in the required information.</p>
        <div class="retry" @click="reApplicationPartnerHandle">Reapply</div>
        <div class="back" @click="$router.back()">Back</div>
      </div>
    </template>

 

    <van-action-sheet v-model="telPicker" :actions="actions" cancel-text="Cancel" close-on-click-action @cancel="telPicker = false" @select="handlePhoneType" />


  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'
import { checkPartner, applicationPartner, reApplicationPartner, applicationqyPartner } from '@/api/zh/partner.js'

export default {
  name:'Partner',
  components:{ MHeader },
  data(){
    return {
      title: '',
      tab:1,          // tabs选中的面板
      form:{
        name:'',
        email:'',
        firstname:'',
        lastname:'',
        phoneType:1,
        phone:''
      },
      actions: [{ name: '1' }, { name: '86' },],
      telPicker:false,
      showPicker: false,
      agree:false,
      status: 0,
      emailTrue: false,
      phoneTrue: false,
      apply_type: 0,
      reset: false

    }
  },

  created() {
    this.checkPartnerHandle()
  },


  mounted(){
    this.tab = this.$route.params.type
    if(this.tab == 1) {
      this.title = "Business partner"
    }else {
      this.title = "Personal partner"
    }
  },

  methods:{

    // 选择区号
    handlePhoneType(it){
      this.form.phoneType = it.name
      this.telPicker = false
    },

    // 提交
    onNext(){
      this.applicationPartnerHandle()
    },
    checkPartnerHandle() {
        // 判断是否已经成为合伙人
        checkPartner().then(res => {
          if(res) {
            // status  审核状态：0未申请 1审核中；2审核通过；3审核未通过
             this.status = res.status
             if (res.apply_type != undefined) {
                this.apply_type = res.apply_type
             }
          }
        })
    },
    applicationPartnerHandle() {
      // 申请合伙人
      // if(!this.agree) {
      //   this.$notify({ type: 'warning', message: 'Read and agree to《 Becoming a Partner Rules and Conditions 》'})
      //   return false
      // }
      if(this.form.name == '' && this.tab == 1) {
        this.$notify({ type: 'warning', message: 'Please enter shop name'})
        return false
      }
      if(this.form.email == '' || this.isTrueEmail(this.form.email)) {
        this.$notify({ type: 'warning', message: 'Please enter contact e-mail'})
        return false
      }
      if(this.form.lastname == '') {
        this.$notify({ type: 'warning', message: 'Please enter your lastname'})
        return false
      }
      if(this.form.firstname == '') {
        this.$notify({ type: 'warning', message: 'Please enter your firstname'})
        return false
      }
      if(this.form.phone == '' || this.isTruePhone(this.form.phone)) {
        this.$notify({ type: 'warning', message: 'Please enter your phone'})
        return false
      }
      let params = {
        apply_type: this.tab == 1 ? 1 : 0,
        surname: this.form.firstname,
        name: this.form.lastname,
        corporate_name: this.form.name,
        email: this.form.email,
        area_code: this.form.phoneType,
        phone: this.form.phone
      }
      if(this.reset) {
        // 重新申请
        reApplicationPartner(params).then(res => {
          if(res) {
            this.$notify({ type: 'success', message: res.msg})
            this.status = 1
          }
        })
      } else {
        if (this.tab == 1) {
          applicationqyPartner(params).then(res => {
            if(res) {
              this.$notify({ type: 'success', message: res.msg})
              this.status = 1
            }
          })
        }else {
          applicationPartner(params).then(res => {
            if(res) {
              this.$notify({ type: 'success', message: res.msg})
              this.status = 1
            }
          })
        }
      }
    },
    reApplicationPartnerHandle() {
      this.reset = true
      this.status = 0
    },
    isTrueEmail(v) {
      /* eslint-disable */
      let patt = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/
      return !patt.test(v)
    },
    isTruePhone(v) {
      return v.length < 10
    }
  },
  watch: {
    'form.email'(val) {
      if(this.isTrueEmail(val)) {
        this.emailTrue = true
      } else {
        this.emailTrue = false
      }
    },
    'form.phone'(val) {
      if(this.isTruePhone(val)) {
        this.phoneTrue = true
      } else {
        this.phoneTrue = false
      }
    }
  }

}
</script>

<style lang="less" scoped>
@import './partner.less';
.application-progress,.application-fail{
    img{width:120px;height:120px;margin:120px auto 0 auto;display: block;}
    h3{margin-top:30px;text-align: center;}
    p{text-align: center;padding:0 40px;margin-top:20px;line-height: 24px;}
    .back,.retry{margin:30px auto 20px auto;width:280px;height:40px;text-align: center;line-height: 40px;font-size: 16px;color:#fff;
    background: linear-gradient(to bottom, #F03C18, #F06448);border-radius: 40px;}
    .retry{background: linear-gradient(to bottom, #0854BF, #1763ce);margin-top:50px}
  }
</style>